import React, { Component } from "react";
import MapDropdown from "./MapDropdown";
import MapOverlay from "./MapOverlay";
import DATA from "../data/mapData";
import RadioButtonsForType from "./RadioButtonsForType";
import RadioButtonsForSide from "./RadioButtonsForSide";
import RadioButtonsForLines from "./RadioButtonsForLines";
import TextInput from "./TextInput";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { URL as API_URL } from "../helpers";
import { Link } from "react-router-dom";

class SubmitNade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapChoice: "Mirage",
      mapImage: "",
      mapAlt: "",
      nadeTitle: "",
      nadeURL: "",
      selectedOption: "Smoke",
      selectedSideOption: "T",
      startX: 0,
      startY: 0,
      midX: 0,
      midY: 0,
      endX: 0,
      endY: 0,
      starterFlag: true,
      midFlag: false,
      lines: "1",
      images: [] // Store an array of selected files
    };
    this.handleMapChange = this.handleMapChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFilesSelect = this.handleFilesSelect.bind(this);
  }

  componentDidMount() {
    DATA.forEach((mapObj) => {
      if (mapObj.mapTitle === this.state.mapChoice) {
        this.setState({ mapImage: mapObj.overlaysrc, mapAlt: mapObj.mapAlt });
      }
    });
  }
  handleFilesSelect = (event) => {
    // Create object URLs for the newly selected files
    console.log(event.target.files); 
    const filesWithPreview = Array.from(event.target.files).map(file => ({
      file,
      preview: URL.createObjectURL(file),
    }));
  
    // Update state with the new files
    this.setState({
      images: [...this.state.images, ...filesWithPreview],
    });
  };
  handleImageRemove = (indexToRemove) => {
    // Revoke the object URL to avoid memory leaks
    URL.revokeObjectURL(this.state.images[indexToRemove].preview);
    this.setState({
      images: this.state.images.filter((_, index) => index !== indexToRemove)
    });
  };
  
  handleMapChange(event) {
    this.setState({ mapChoice: event.target.value });
    DATA.forEach((mapObj) => {
      if (mapObj.mapTitle === event.target.value) {
        this.setState({ mapImage: mapObj.overlaysrc, mapAlt: mapObj.mapAlt });
      }
    });
  }

  

  handleSubmit(event) {
    event.preventDefault();
    
    const mapChoiceAdjusted = this.state.mapChoice === "Dust ll" ? "dust2" : this.state.mapChoice;
    const formData = new FormData();
    console.log(this.state.images);
    // Append images to formData if they exist
    if (this.state.images.length > 0) {
      this.state.images.forEach((image) => {
        formData.append('files', image.file); // Use image.file instead of just file
      });
    
    }
  
    // Append other form fields to formData
    formData.append("mapChoice", mapChoiceAdjusted.charAt(0).toLowerCase() + mapChoiceAdjusted.slice(1));
    formData.append("nadeTitle", this.state.nadeTitle);
    formData.append("description", this.state.description);
    formData.append("nadeURL", this.state.nadeURL);
    formData.append("selectedOption", this.state.selectedOption);
    formData.append("selectedSideOption", this.state.selectedSideOption);
    formData.append("startX", this.state.startX);
    formData.append("startY", this.state.startY);
    formData.append("midX", this.state.midX);
    formData.append("midY", this.state.midY);
    formData.append("endX", this.state.endX);
    formData.append("endY", this.state.endY);
    formData.append("lines", this.state.lines);
    formData.append("loggedIn", this.props.loggedIn);
  
    axios.post(`${API_URL}/submitNade`, formData, {      
      headers: {
        'Content-Type':'multipart/form-data'
      },
      withCredentials: true,
    })
    .then((res) => {
      // Reset form fields
      this.setState({
        nadeTitle: "",
        nadeURL: "",
        description: "",
        images: [],
        startX: 0,
        startY: 0,
        midX: 0,
        midY: 0,
        endX: 0,
        endY: 0,
        starterFlag: true
      });
      NotificationManager.success("Pending approval!", "Success!", 4000);
    })
    .catch((error) => {
      // Handle errors
      if (error.response && error.response.data.errors) {
        error.response.data.errors.forEach((err) => {
          NotificationManager.error(err.msg, "Error", 4000);
        });
      } else {
        NotificationManager.error("Error submitting nade.", "Error", 4000);
      }
    });
  }
  

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleUpload = (event) => {
    this.setState({ images: [...event.target.files] })    
  }
  mouseClicker = (event) => {
    var root = document.getElementById("svgID");
    var uupos = root.createSVGPoint();
    uupos.x = event.clientX;
    uupos.y = event.clientY;
    var ctm = event.target.getScreenCTM();
    if ((ctm = ctm.inverse())) {
      uupos = uupos.matrixTransform(ctm);
    }
    let x = Math.floor(uupos.x);
    let y = Math.floor(uupos.y);
    if (this.state.starterFlag) {
      this.setState({
        startX: x,
        startY: y,
        starterFlag: false,
        midFlag: true
      });
    } else if (this.state.midFlag && this.state.lines === "2") {
      this.setState({ midX: x, midY: y, midFlag: false });
    } else {
      this.setState({ endX: x, endY: y, starterFlag: true });
    }
  };

  switchSides = () => {
    this.state.lines === 1
      ? this.setState({ lines: 2 })
      : this.setState({ lines: 1 });
  };

  render() {
    return (
      <div className="container">
        <h2 className="mapTitle">Submit your own</h2>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="form-group">
            <MapDropdown
              handleMapChange={this.handleMapChange}
              mapChoice={this.state.mapChoice}
            />
          </div>
          <div className="form-group">
            <label className="label">Title: </label>
            <TextInput
              inputText={this.state.nadeTitle}
              name="nadeTitle"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <label className="label">Description: </label>
            <TextInput
              inputText={this.state.description}
              name="description"
              onChange={this.handleChange}
            />
          </div>          
          <div className="form-group">
            <label className="label">Upload Images:</label>
            <input
              type="file"
              className="form-control-file"
              onChange={this.handleFilesSelect}
              accept="image/*" // Accept only image files
            />
            {/* Display thumbnails of selected images */}
            <div className="image-preview">
              {this.state.images.map((fileWithPreview, index) => (
                <div key={index} className="image-thumbnail">
                  <img src={fileWithPreview.preview} alt={`preview ${index}`} />
                  <span className="remove-image" onClick={() => this.handleImageRemove(index)}>X</span>
                </div>
              ))}
            </div>
          </div>
          
          <div className="form-group">
            <label className="label">YouTube Embed URL:</label>
            <Link to="/instructions" >
              What's this?
            </Link>
            <TextInput
              inputText={this.state.nadeURL}
              name="nadeURL"
              onChange={this.handleChange}
              required title=""
              placeholder={"https://www.youtube.com/embed/UCKJ5w81MjM"}
            />
          </div>
          <div className="form-group">
            <RadioButtonsForType
              selectedOption={this.state.selectedOption}
              handleChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <RadioButtonsForSide
              selectedSideOption={this.state.selectedSideOption}
              handleChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <RadioButtonsForLines
              lines={this.state.lines}
              handleChange={this.handleChange}
            />
          </div>
          <div className="form-group">
            <button className="btn btn-primary" type="submit">
              Submit Nade
            </button>
          </div>
          <div className="form-group">
            <MapOverlay
              show={false}
              showUserNade={true}
              mapImage={this.state.mapImage}
              mapAlt={this.state.mapAlt}
              mouseClicker={this.mouseClicker}
              startX={this.state.startX}
              startY={this.state.startY}
              midX={this.state.midX}
              midY={this.state.midY}
              endX={this.state.endX}
              endY={this.state.endY}
              selectedOption={this.state.selectedOption}
              nadeClass={"userNadeNormal"}
              lines={this.state.lines}
              icon={"list"}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default SubmitNade;
