import React, { Component } from "react";
import TerroristNades from "./TerroristNades";
import CounterTerroristNades from "./CounterTerroristNades";

class BothNades extends Component {
  render() {
    // console.log("bothnades");
    // console.log(this.props.nadeData);
    if (this.props.show) {
      // Determine if filtering is needed
      // console.log(this.props.bothSides);
      const filteredNadeDataT = this.props.bothSides
        ? this.props.nadeData
        : this.props.nadeData.filter(nade => nade.side === "T");

      const filteredNadeDataCT = this.props.bothSides
        ? this.props.nadeData
        : this.props.nadeData.filter(nade => nade.side === "CT");

      return (
        <React.Fragment>
          <TerroristNades
            match={this.props.match}
            tOrCt={this.props.tOrCt}
            nadeData={filteredNadeDataT}
            smokesFlag={this.props.smokesFlag}
            flashesFlag={this.props.flashesFlag}
            molotovsFlag={this.props.molotovsFlag}
            hesFlag={this.props.hesFlag}
            nadeClass={this.props.nadeClass}
            side={this.props.nadeData}
          />
          <CounterTerroristNades
            match={this.props.match}
            tOrCt={this.props.tOrCt}
            nadeData={filteredNadeDataCT}
            smokesFlag={this.props.smokesFlag}
            flashesFlag={this.props.flashesFlag}
            molotovsFlag={this.props.molotovsFlag}
            hesFlag={this.props.hesFlag}
            nadeClass={this.props.nadeClass}
            side={this.props.nadeData}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default BothNades;