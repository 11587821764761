import React from "react";
import Maplist from "./Maplist";

const Maps = () => {
  return (
    <div className="container">
      <h2>Maps</h2>
      <Maplist />
    </div>
  );
};

export default Maps;
