import React, { Component } from "react";
import axios from "axios";
import { URL } from "../helpers";
import NoMatch from "./NoMatch";
import LikesDislikes from "./LikesDislikes";
import { NotificationManager } from "react-notifications";


class NadePage extends Component {
  state = {
    nadeTitle: "",
    nadeDescription: "",
    authorID: "",
    author: "",
    videoURL: "",
    dateSubmitted: "",
    showNoMatchComponent: false,
    likes: "",
    dislikes: "",
    likesArr: "",
    dislikesArr: "",
    like: "thumbs-up",
    dislike: "thumbs-down",
    loggedIn: "",
    user: {},
    images: [],
    showModal: false,
    selectedImage: null
  };

  openModal = (image) => {
    this.setState({ 
      showModal: true,
      selectedImage: image
    });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleThumbnailClick(image) {
    this.setState({ selectedImage: image });
  }
  componentDidMount() {
    // API call to validate user being logged in for fresh load to this URL
    axios(`${URL}/validateSession`, {
      method: "get",
      withCredentials: true
    })
    .then(res => {
      if (res.data.msg === "yes") {
        this.setState({ loggedIn: true });
      } else if (res.data.msg === "no") {
        this.setState({ loggedIn: false });
      }
    })
    .then(() => {
      axios(`${URL}/user`, {
        method: "get",
        withCredentials: true
      })
      .then(res => {
        if (res.data.email) {
          this.setState({ user: res.data });
        }
      })
      .then(() => {
        if (this.state.loggedIn) {
          if (this.state.likesArr.includes(this.props.user._id)) {
            this.setState({ like: "thumps-up liked" });
          }
          if (this.state.dislikesArr.includes(this.props.user._id)) {
            this.setState({ dislike: "thumps-up disliked" });
          }
        }
      });
    });
    console.log("loadnadevideo");
    // API endpoint to load nade video
    axios(`${URL}/loadNadeVideo`, {
      method: "post",
      withCredentials: true,
      data: {
        nadeID: this.props.match.params.id
      }
    })
    .then(res => {
      if (res.data) {
        let dateReturned = new Date(res.data.date);
        let month = dateReturned.getMonth() + 1;
        let day = dateReturned.getDate();
        let year = dateReturned.getFullYear();
        let fullDate = month + "/" + day + "/" + year;
        let numLikes = res.data.likesArr.length;
        let numDislikes = res.data.dislikesArr.length;
        this.setState({
          nadeTitle: res.data.title,
          nadeDescription: res.data.description,
          authorID: res.data.authorID,
          videoURL: res.data.url,
          dateSubmitted: fullDate,
          likes: numLikes,
          dislikes: numDislikes,
          likesArr: res.data.likesArr,
          dislikesArr: res.data.dislikesArr
        });
        // console.log("almost");
        axios(`${URL}/getAuthorUserName`, {
          method: "post",
          withCredentials: true,
          data: {
            authorID: this.state.authorID
          }
        })
        .then(res => {
          this.setState({ author: res.data });
        });
      }
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.name === "CastError") {
        this.setState({ showNoMatchComponent: true });
      }
    });
  
    // New API endpoint to load images associated with the nade
    axios(`${URL}/loadNadeImages`, { // Adjust this endpoint as needed
      method: "post",
      withCredentials: true,
      data: {
        nadeID: this.props.match.params.id
      }
    })
    .then(res => {
      if (res.data && res.data.images) {
        this.setState({ images: res.data.images });
      }
    })
    .catch(error => {
      // Handle error for image loading
    });
  }
  

  like = () => {
    if (this.state.loggedIn) {
      axios(`${URL}/likeNadePost`, {
        method: "post",
        withCredentials: true,
        data: {
          userID: this.state.user._id,
          nadeID: this.props.match.params.id
        }
      })
        .then((res) => {
          if (res.data.msg === "removed") {
            this.setState({
              likes: this.state.likes - 1,
              like: "thumbs-up"
            });
          }
          if (res.data.msg === "added") {
            this.setState({
              likes: this.state.likes + 1,
              like: "thumbs-up liked"
            });
          }
          if (res.data.msg === "removed and added") {
            this.setState({
              likes: this.state.likes + 1,
              dislikes: this.state.dislikes - 1,
              like: "thumbs-up liked",
              dislike: "thumbs-down"
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(error.toString(), "Error", 4000);
        });
    } else {
      NotificationManager.error("You must be logged in!", "Error!", 4000);
    }
  };

  dislike = () => {
    if (this.state.loggedIn) {
      axios(`${URL}/dislikeNadePost`, {
        method: "post",
        withCredentials: true,
        data: {
          userID: this.state.user._id,
          nadeID: this.props.match.params.id
        }
      })
        .then((res) => {
          if (res.data.msg === "removed") {
            this.setState({
              dislikes: this.state.dislikes - 1,
              dislike: "thumbs-down"
            });
          }
          if (res.data.msg === "added") {
            this.setState({
              dislikes: this.state.dislikes + 1,
              dislike: "thumbs-down disliked"
            });
          }
          if (res.data.msg === "removed and added") {
            this.setState({
              likes: this.state.likes - 1,
              dislikes: this.state.dislikes + 1,
              like: "thumbs-up",
              dislike: "thumbs-down disliked"
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(error.toString(), "Error", 4000);
        });
    } else {
      NotificationManager.error("You must be logged in!", "Error!", 4000);
    }
  };

  render() {
    if (this.state.showNoMatchComponent) {
      return <NoMatch />;
    } else {
      const videoEmbed = this.state.videoURL.includes("https://www.youtube.com/embed/")
        ? (
          <div
            className="embed-responsive embed-responsive-16by9"
            dangerouslySetInnerHTML={{
              __html: `
                <iframe
                  src="${this.state.videoURL}?autoplay=1"
                  title="${this.state.nadeTitle}"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen>
                </iframe>`
            }}
          ></div>
        )
        : null;
        const mainImage = this.state.selectedImage || (this.state.images.length > 0 ? this.state.images[0] : null);
        const otherImages = this.state.images.filter(image => image !== mainImage);
            
      return (
        <div className="container">
          {videoEmbed}
          {mainImage && (
          <div className="first-image-container image-hover-effect" onClick={() => this.openModal(mainImage)}>
          <img 
            src={`${URL}/images/${mainImage}`} 
            alt="Main Image" 
            className="first-image"
          />
        </div>
      )}

{otherImages.length > 0 ? (
        <div className="thumbnail-row">
          {otherImages.map((image, index) => {
            // Debugging: Log each thumbnail URL
            // console.log("Thumbnail URL:", `${URL}/images/${image}`);

            return (
              <img 
                key={index} 
                src={`${URL}/images/${image}`} 
                alt={`Thumbnail ${index + 1}`} 
                className="thumbnail"
                onClick={() => this.handleThumbnailClick(image)}
              />
            );
          })}
        </div>
      ) : (
        <p>No additional images to display.</p> // Message when no thumbnails are present
      )}


  
          <div className="nadeTitleRow d-flex w-100 justify-content-between">
            <div className="nade-info">
              <h5 className="nade-title">{this.state.nadeTitle}</h5>
              <p className="nade-description">{this.state.nadeDescription}</p>
            </div>
            <LikesDislikes
              likes={this.state.likes}
              dislikes={this.state.dislikes}
              like={this.like}
              dislike={this.dislike}
              userLikes={this.state.like}
              userDislikes={this.state.dislike}
            />
          </div>
  
          <div>
            <label className="label">Author</label>
            <p>{this.state.author}</p>
          </div>
  
          <div>
            <label className="label">Date Submitted</label>
            <p>{this.state.dateSubmitted}</p>
          </div>
          <Modal show={this.state.showModal} onClose={this.closeModal}>
  <img 
    src={`${URL}/images/${this.state.selectedImage}`} 
    alt="Selected" 
    onClick={this.closeModal} // Close modal on image click
    style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
  />
</Modal>
        </div>
        
      );
    }
  }
}

const Modal = ({ show, children, onClose }) => {
  if (!show) {
    return null;
  }

  const handleBackdropClick = (e) => {
    // Check if the click is on the backdrop (not the content)
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div className="modal-content" onClick={handleClose}>
        {children}
      </div>
    </div>
  );
};


export default NadePage;

