import React, { Component } from "react";

class KeyBinds extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="container">
        <h2>Keybinds</h2>
        <div className="rowContent col-xs-12 col-sm-12 col-md-8 col-lg-8">
        <h3>Walk-Jumpthrow</h3>
        <p>
        alias "+throwaction" "-attack;-attack2"<br/>
        alias "+runthrow" "+forward;+jump"<br/>
        alias "-runthrow" "-forward;-jump"<br/>
        <br/>
        bind "alt" "+runthrow;+throwaction"
        </p>
        <br/>
        <br/>
        <h3>Jumpthrow</h3>
        <p>
        bind "n" "-attack;-attack2"
        </p>
     
      </div>
      </div>
    );
  }
}

export default KeyBinds;
