import React, { Component } from "react";
import CTList from "./CTList";
import TList from "./TList";

class BothNadesList extends Component {
  render() {
    if (this.props.bothSides) {
      // When bothSides is true, pass all nadeData to both CTList and TList without filtering
      return (
        <>
          <CTList
            match={this.props.match}
            nadeData={this.props.nadeData}
            smokesFlag={this.props.smokesFlag}
            flashesFlag={this.props.flashesFlag}
            molotovsFlag={this.props.molotovsFlag}
            hesFlag={this.props.hesFlag}
          />
          <TList
            match={this.props.match}
            nadeData={this.props.nadeData}
            smokesFlag={this.props.smokesFlag}
            flashesFlag={this.props.flashesFlag}
            molotovsFlag={this.props.molotovsFlag}
            hesFlag={this.props.hesFlag}
          />
        </>
      );
    } else {
      // When bothSides is false, filter nadeData based on tOrCt
      const filteredNadeData = this.props.nadeData.filter(nade => nade.side === this.props.tOrCt);
      const ListComponent = this.props.tOrCt === "CT" ? CTList : TList;
      return (
        <ListComponent
          match={this.props.match}
          nadeData={filteredNadeData}
          smokesFlag={this.props.smokesFlag}
          flashesFlag={this.props.flashesFlag}
          molotovsFlag={this.props.molotovsFlag}
          hesFlag={this.props.hesFlag}
        />
      );
    }
  }
}

export default BothNadesList;